import config from "./config"

export const updateInvoices = async (): Promise<void> => {
    const endpoint = config.functions.updateInvoices;

    const response = await fetch(endpoint);
    if (!response.ok) {
        throw new Error(`Failed to call function: ${response.statusText}`);
    }
}

export const finalizeInvoice = async (token: string | undefined, invoiceId: string): Promise<void> => {
    const endpoint = config.functions.finalizeInvoice;
    const payload = { invoiceId };
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(payload)
    });

    if (!response.ok) {
        const bodyText = await response.text();
        throw new Error(`Failed to call function: ${bodyText}`);
    }
}

export const handleAzureCode = async (code: string): Promise<void> => {
    const endpoint = config.functions.codeAzureAuth;
    const response = await fetch(`${endpoint}?code=${code}`);
    if (!response.ok) {
        throw new Error(`Failed to call function: ${response.statusText}`);
    }
}
